// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: ("100": $gray-100, "200": $gray-200, "300": $gray-300, "400": $gray-400, "500": $gray-500, "600": $gray-600, "700": $gray-700, "800": $gray-800, "900": $gray-900);
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #1d5195;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #226666;
$cyan:    #0dcaf0;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: ("blue":       $blue, "indigo":     $indigo, "purple":     $purple, "pink":       $pink, "red":        $red, "orange":     $orange, "yellow":     $yellow, "green":      $green, "teal":       $teal, "cyan":       $cyan, "white":      $white, "gray":       $gray-600, "gray-dark":  $gray-800);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary:       $teal;
$secondary:     $gray-600;
$success:       $green;
$info:          $blue;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;
$light-blue: lighten($blue, 57%);
$light-green: #d5ffd8;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: ("primary":    $primary, "secondary":  $secondary, "success":    $success, "info":       $info, "warning":    $warning, "danger":     $danger, "light":      $light, "dark":       $dark, "blue": $blue, "red": $red, "light-green": $light-green, "light-blue": $light-blue);
// scss-docs-end theme-colors-map

// scss-docs-start theme-colors-rgb
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
// scss-docs-end theme-colors-rgb

$navbar-light-active-color: darken($green, 20%);
