@import "variables";

$default: $primary;
$default_dark: darken($primary, 7%);

$success: $success;
$success_dark: darken($success, 7%);

$danger: $danger;
$danger_dark: darken($danger, 7%);

$info: $info;
$info_dark: darken($info, 7%);

$warning: $warning;
$warning_dark: darken($warning, 7%);

$awesome: #685dc3 !default;
$awesome_dark: #4c3fb1 !default;

$default_timer: $default_dark;
$default_timer_filler: #fff !default;

$success_timer: $success_dark;
$success_timer_filler: #fff !default;

$danger_timer: $danger_dark;
$danger_timer_filler: #fff !default;

$info_timer: $info_dark;
$info_timer_filler: #fff !default;

$warning_timer: $warning_dark;
$warning_timer_filler: #fff !default;

$awesome_timer: #685dc3 !default;
$awesome_timer_filler: #fff !default;

@import "~react-notifications-component/dist/scss/notification";

.notifications-component {
  opacity: 0.8; }
