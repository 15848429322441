@import "src/styles/variables";

.custom-control {
   :global(.is-invalid) & {
    border-color: $red; }
   :global(.is-valid) & {
    border-color: $green; } }
.custom-indicator-container {
  svg {
     :global(.is-invalid) & {
      stroke-width: 1;
      color: $red; }
     :global(.is-valid) & {
      stroke-width: 1;
      color: $green; } } }
