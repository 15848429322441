@import "~animate.css/animate.css";
@import "styles/custom_bootstrap";
@import "styles/custom_react_notification";

html {
  font-size: 13px; }


.page-title {
  text-align: center;
  font-size: 1.7rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem; }

.cursor-pointer {
  cursor: pointer; }

// ------- react-data-table customization
// enlarge clickable area for sorting
.rdt_TableHeadRow {
  background-color: tomato;
  .rdt_TableCol {
    background-color: $gray-200;
    text-transform: uppercase; }
  .rdt_TableCol_Sortable {
    min-width: 80%;
    span {
      margin-left: 1rem;
      color: lighten($red, 20%); } } }


.text-sm {
  font-size: 0.8rem; }
